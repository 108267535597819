<template>
	<!-- <v-sheet class="" style="height: calc(100vh - 84px)"> -->
	<!-- <v-layout class="flex-wrap">
			<div class="pt-3 d-flex align-center justify-content-between w-100">
				<v-tabs
					v-model="dataTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent border-bottom w-100"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#seo_executive">Seo Executive</v-tab>
				</v-tabs>
			</div>
		</v-layout> -->
	<div class="">
		<!-- <div class="">
			<v-tabs-items v-model="dataTab" class="transparent">
				<v-tab-item value="seo_executive">
					<v-sheet class="recurring-main-listing position-relative" style="height: calc(100vh - 125px)"> -->

		<v-layout class="flex-wrap">
			<div class="pt-3 d-flex align-start justify-space-between w-100">
				<!-- <h4 class="fw-500 title-text mr-4 mt-0" style="white-space: nowrap;margin:top:0px">
									Domain
								</h4> -->
				<!-- <v-flex class="custom">
									<v-select
										v-model="status"
										solo
										class="listing-select"
										hide-details
										:items="domain_status_list"
									>
										<template v-slot:item="{ item }">
											<v-list-item-content>
												<v-list-item-title v-text="item.text"></v-list-item-title>
												<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
													<span>{{ item.status_count }}</span>
												</v-avatar>
											</v-list-item-action>
										</template>
									</v-select>
								</v-flex> -->

				<!-- <span style="width: 240px">
									<v-select :items="domainStatusList" outlined hide-details v-model="projectStatus">
										<template v-slot:selection="data">
											<v-chip v-bind="data.attrs" :input-value="data.selected" class="pl-0">
												<v-avatar class="mr-3" :color="data.item.color"> </v-avatar>
												{{ data.item.text }}
											</v-chip>
										</template>
										<template v-slot:item="data">
											<template>
												<v-list-item-avatar class="ma-0" width="30" min-width="30">
													<v-avatar size="20" :color="data.item.color"> </v-avatar>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title v-html="data.item.text"></v-list-item-title>
												</v-list-item-content>
											</template>
										</template>
									</v-select>
								</span> -->
				<v-spacer></v-spacer>
				<div class="d-flex align-start" style="max-height: 30px">
					<v-select
						style="width: 250px"
						height="30px"
						placeholder="Select Entity"
						outlined
						clearable
						hide-details
						:items="entityList"
						v-model="customfilter.entity"
						@change="statusFilterRows"
						:menu-props="{ offsetY: true, bottom: true, contentClass: 'rounded-lg border' }"
					>
						<template #selection="{ item }">
							<div class="d-flex align-center">
								<div class="fw-600 text-uppercase">
									{{ item.text }}
								</div>
							</div>
						</template>
						<template #item="{ item }">
							<div class="d-flex align-center py-1">
								<div>
									<ImageTemplate
										style="max-width: 30px; height: 30px; box-shadow: 0px 0px 2px 0px #888"
										circle
										:src="`media/logos/${item.value}.png`"
										class="mr-2 mt-1"
									></ImageTemplate>
								</div>
								<div class="fw-600 text-uppercase">
									{{ item.text }}
								</div>
							</div>
						</template>
					</v-select>

					<div class="mx-3" style="max-height: 30px">
						<SelectInput
							hide-details
							clearable
							height="30px"
							:items="service_providers"
							style="margin-top: 0px !important; min-width: 215px; max-width: 265px"
							placeholder="Service Provider 1"
							item-text="text"
							item-value="value"
							v-model="customfilter.service_provider"
							@change="statusFilterRows"
							:menu-props="{ offsetY: true, bottom: true, contentClass: 'rounded-lg border' }"
						>
							<template #selected-item="{ item }">
								<div class="d-flex align-center">
									<div class="fw-600 text-uppercase">
										{{ item.text }}
									</div>
								</div>
							</template>
						</SelectInput>
					</div>

					<!-- <div class="mr-4">
						<SelectInput
							hide-details
							clearable
							height="30px"
							:items="[
								{ title: 'Active', value: 1 },
								{ title: 'InActive', value: 2 },
							]"
							style="margin-top: 0px !important; width: 215px"
							placeholder="Status"
							item-text="title"
							item-value="value"
							v-model="status"
							@change="statusFilterRows"
							:menu-props="{ offsetY: true, bottom: true }"
						></SelectInput>
					</div> -->

					<div class="mr-3">
						<SelectInput
							hide-details
							clearable
							height="30px"
							:items="['bthrust', 'client']"
							style="margin-top: 0px !important; width: 215px"
							placeholder="Billing"
							v-model="customfilter.billing"
							@change="statusFilterRows"
							:menu-props="{ offsetY: true, bottom: true, contentClass: 'rounded-lg border' }"
						>
							<template #selected-item="{ item }">
								<div class="d-flex align-center">
									<div class="fw-600 text-uppercase">
										{{ item }}
									</div>
								</div>
							</template>
						</SelectInput>
					</div>

					<DateRangePicker
						hide-details
						hide-top-margin
						clearable
						:inline-style="{ maxWidth: '235px', minWidth: '235px' }"
						class="mr-3"
						placeholder="Date Range"
						v-model="customfilter.date_range"
						:disablePastFrom="
							customfilter.date_range && Array.isArray(customfilter.date_range)
								? customfilter.date_range[0]
								: ''
						"
						@change="
							() => {
								if (customfilter.date_range && customfilter.date_range.length > 1) {
									statusFilterRows();
								}
							}
						"
						@click:clear="(customfilter.date_range = null), statusFilterRows()"
					></DateRangePicker>

					<div class="">
						<v-btn
							depressed
							color="white"
							class="red darken-4 ml-3"
							tile
							text
							min-height="30"
							@click="resetFilters"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
					<!-- <v-text-field
										outlined
										hide-details
										placeholder="To date..."
										v-model="notification"
										class="w-10 mr-2"
									></v-text-field> -->
					<!-- <v-select
										:items="domainStatusList"
										outlined
										hide-details
										class="w-10"
										v-model="projectStatus"
									>
										<template v-slot:selection="data">
											<v-chip v-bind="data.attrs" :input-value="data.selected" class="pl-0">
												<v-avatar class="mr-3" :color="data.item.color"> </v-avatar>
												{{ data.item.text }}
											</v-chip>
										</template>
										<template v-slot:item="data">
											<template>
												<v-list-item-avatar class="ma-0" width="30" min-width="30">
													<v-avatar size="20" :color="data.item.color"> </v-avatar>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-text v-html="data.item.text"></v-list-item-text>
												</v-list-item-content>
											</template>
										</template>
									</v-select> -->

					<v-btn color="blue darken-4 white--text" tile min-height="30" class="ml-3">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn>

					<v-tooltip top>
						<template #activator="{ on, attrs }">
							<v-btn
								color="blue darken-4 white--text"
								v-on="on"
								v-bind="attrs"
								tile
								class="mx-3"
								min-height="30"
								@click="exportCSV()"
							>
								<!-- min-height="30" -->
								<v-icon color="white">mdi-export</v-icon>
							</v-btn>
						</template>
						<span>Export</span>
					</v-tooltip>

					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								color="blue darken-4"
								class="text-white"
								tile
								min-height="30"
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('domain')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										@change="updateTableVisiblity('domain', cols.key)"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag
									</v-icon>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>

					<!-- <v-btn
										color="blue darken-4 white--text"
										tile
										min-height="30"
										class="ml-3"
										@click="projectCreateDialog = true"
									>
										<v-icon color="white">mdi-plus</v-icon>
									</v-btn> -->
				</div>
			</div>
		</v-layout>
		<v-layout
			class="page-summary show mt-2"
			style="display: grid; grid-template-columns: repeat(4, 1fr)"
		>
			<PageSummeryBox
				color="blue"
				text="All Domain"
				:count="count && count.total ? count.total : 0"
				@click="status != 0 && (changeActiveTab(0), statusFilterRows())"
			/>
			<PageSummeryBox
				color="green"
				text="Active"
				:count="count && count.total_active ? count.total_active : 0"
				@click="status != 1 && (changeActiveTab(1), statusFilterRows())"
			/>
			<PageSummeryBox
				color="red"
				text="Expired"
				:count="count && count.total_expire ? count.total_expire : 0"
				@click="status != 2 && (changeActiveTab(2), statusFilterRows())"
			/>
			<PageSummeryBox
				color="orange"
				text="Expired In 1 Month"
				:count="count && count.total_expire_1_month ? count.total_expire_1_month : 0"
				@click="status != 3 && (changeActiveTab(3), statusFilterRows())"
			/>
		</v-layout>

		<div class="mb-3 overflow-auto mt-2">
			<v-btn-toggle v-model="toggle_exclusive" mandatory color="primary" dense tile>
				<v-btn
					class="btn-border"
					:class="[
						{ 'blue darken-4 white--text': customfilter.alphabet == null },
						{ 'blue--text': customfilter.alphabet != null },
					]"
					@click="(customfilter.alphabet = null), statusFilterRows()"
				>
					ALL
				</v-btn>
				<v-btn
					class="btn-border"
					:class="[{ 'blue darken-4 white--text': customfilter.alphabet == data }]"
					v-for="(data, index) in filter"
					:key="'$_alphabet_' + index"
					@click="(customfilter.alphabet = data), statusFilterRows()"
				>
					{{ data }}
				</v-btn>
			</v-btn-toggle>
		</div>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Barcode #, Domain, Sales Person, Service Provider"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>

		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>

		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height" ref="btt_height">
				<table class="w-100">
					<thead>
						<tr>
							<th
								v-for="(th, th_idx) in allowedSortedThead"
								:key="th.key + '_' + th_idx"
								:width="th.width"
							>
								<template v-if="th.checkbox">
									<v-checkbox
										dense
										v-model="checkAll"
										:ripple="false"
										hide-details
										color="blue darken-4"
										class="m-0 p-0"
									></v-checkbox>
								</template>
								<template v-else>
									{{ th.name }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody.length">
							<tr v-for="(td, td_idx) in tbody" :key="'domain_' + td.id + '_' + td_idx">
								<template v-for="(th, th_idx) in allowedSortedThead">
									<td
										:key="'domain_data_$_' + th.key + '_' + th_idx"
										:width="th.width"
										@click="routeToDetail(th, td.id)"
									>
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												:ripple="false"
												:value="td.id"
												hide-details
												v-model="selectedRows"
												multiple
												@change="selectRow"
												class="m-0 p-0"
												color="blue darken-4"
											></v-checkbox>
										</template>

										<template v-else-if="th.key == 'action'">
											<div class="d-flex justify-space-around align-center">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															@click="doAction('update', td)"
															size="22"
															v-on="on"
															v-bind="attrs"
															class="cursor-pointer"
															color="blue"
															>mdi-pencil
														</v-icon>
													</template>
													<span>Edit Domain</span>
												</v-tooltip>

												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															size="22"
															v-on="on"
															@click.prevent.stop="doAction('delete')"
															v-bind="attrs"
															class="cursor-pointer"
															color="red"
														>
															mdi-delete
														</v-icon>
													</template>
													<span>Delete Domain</span>
												</v-tooltip>
											</div>
										</template>

										<template v-else-if="th.key == 'entity'">
											<div class="d-flex align-center">
												<ImageTemplate
													v-if="td.company_type == 'genic-solution'"
													style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
													src="media/logos/genic-solution.png"
													circle
													class="mr-2"
												></ImageTemplate>
												<ImageTemplate
													v-if="td.company_type == 'bthrust'"
													style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
													src="media/logos/bthrust.png"
													circle
													class="mr-2"
												></ImageTemplate>
											</div>
										</template>

										<template v-else-if="th.key == 'project_details'">
											<div class="">
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class="mb-1">Domain : </label>
													<div v-if="td.name">
														<v-chip class="white--text" label color="teal lighten-1" x-small>
															{{ td.name }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no project</em>
													</div>
												</div>
												<div class="d-flex justify-space-between">
													<label style="width: 80px; font-weight: 600" class=""> Status : </label>
													<v-chip
														class="mb-1 mr-1"
														label
														x-small
														v-if="getValue(project_status, `${getValue(td, 'projectData.status')}.title`)"
														:color="`${getValue(
															project_status,
															`${getValue(td, 'projectData.status')}.color`
														)} white--text`"
													>
														{{ getValue(project_status, `${getValue(td, "projectData.status")}.title`) }}
													</v-chip>
													<em v-else class="text-muted">no status</em>
												</div>
												<div class="d-flex justify-space-between mr-1">
													<label style="width: 80px; font-weight: 600" class="mb-1">Sales : </label>
													<div class="d-flex align-center">
														<ImageTemplate
															v-if="getValue(td, 'sales_person.profile_img')"
															style="
																margin-right: 8px;
																max-width: 30px;
																height: 30px;
																box-shadow: 0px 0px 1px 0px #888;
															"
															:src="getValue(td, 'sales_person.profile_img')"
															circle
														></ImageTemplate>

														<div v-if="getValue(td, 'sales_person.display_name')">
															{{ getValue(td, "sales_person.display_name") }}
														</div>
														<em v-else>no sales owener</em>
													</div>
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'service_provider'">
											<div class="text-center">
												<template v-if="td.service_provider">
													<v-chip label outlined color="cyan darken-4">
														{{ td.service_provider }}
													</v-chip>
												</template>
												<template v-else>
													<em class="text-muted">no service provider</em>
												</template>
											</div>
										</template>

										<template v-else-if="th.key == 'billing'">
											<div class="text-center">
												<v-chip
													class="white--text"
													label
													:color="td.type == 'bthurst' ? 'orange' : 'teal lighten-1'"
													>{{ td.type }}
												</v-chip>
											</div>
										</template>

										<template v-else-if="th.key == 'date'">
											<div class="position-relative" style="display: grid; place-items: center">
												<div>
													<label style="width: 60px; font-weight: 600" class="mb-1">Start :</label>
													<v-chip
														v-if="td.start_date"
														label
														color="orange darken-1 white--text"
														outlined
														text-color=""
													>
														<span class="fw-500"> {{ formatDate(td.start_date) }}</span>
													</v-chip>
													<em class="text-muted" v-else>no start date</em>
												</div>
												<div class="mt-1">
													<label style="width: 60px; font-weight: 600" class="mb-1">End :</label>
													<v-chip v-if="td.end_date" label color="green white--text" outlined text-color="">
														<span class="fw-500"> {{ formatDate(td.end_date) }}</span>
													</v-chip>
													<em class="text-muted" v-else>no end date</em>
												</div>

												<div
													v-if="date_difference(formatDate_Raw(new Date()), td.end_date) < 0"
													class="tilted_watermark mb-0 red--text px-2 py-1 position-absolute rounded text-center"
												>
													Expired
												</div>
											</div>
										</template>

										<template v-else-if="th.key == 'cost/sales_price'">
											<div class="d-flex">
												<label style="width: 80px; font-weight: 600" class="mb-1">Cost Price : </label>
												<v-chip label color="green white--text" outlined text-color="">
													<span class="fw-500"> {{ formatMoney(td?.cost) }}</span>
												</v-chip>
											</div>
											<div class="d-flex">
												<label style="width: 80px; font-weight: 600" class="mb-1">Sale Price : </label>
												<v-chip
													class="mt-1"
													label
													color="deep-orange lighten-1 white--text"
													outlined
													text-color=""
												>
													<span class="fw-500"> {{ formatMoney(td?.sales_price) }}</span>
												</v-chip>
											</div>
										</template>

										<template v-else-if="th.key == 'remark'">
											<div
												class="line-clamp-1"
												style="
													max-width: 250px;
													text-wrap: initial;
													display: -webkit-box;
													-webkit-line-clamp: 2;
													-webkit-box-orient: vertical;
													overflow: hidden;
												"
											>
												<ShowTextAreaValue :object="td" object-key="remark" label="remark"> </ShowTextAreaValue>
											</div>
										</template>

										<template v-else-if="th.key == 'status'">
											<div @click.stop class="d-flex justify-space-between align-center">
												<template v-if="td.domain_status == 'draft'">
													<v-chip label class="white--text" color="cyan" v-if="getValue(td, 'domain_status')">
														{{
															getValue(td, "domain_status") &&
															td.domain_status.charAt(0).toUpperCase() + td.domain_status.slice(1)
														}}
													</v-chip>
												</template>
												<template v-else>
													<v-chip
														label
														class="white--text"
														:color="td.domain_status == 'active' ? 'green' : 'red'"
														v-if="getValue(td, 'domain_status')"
													>
														{{
															getValue(td, "domain_status") &&
															td.domain_status.charAt(0).toUpperCase() + td.domain_status.slice(1)
														}}
													</v-chip>
												</template>
											</div>
										</template>

										<template v-else-if="th.key == 'added_at'">
											<Chip class="mr-1" outlined :text="td.added_at" textColor="green" color="green"></Chip>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="8">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no projects at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<v-row class="my-2" v-if="tbody.length">
			<v-col md="6" class="">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					class="listing-pagination"
					@input="updatePagination"
				></v-pagination>
			</v-col>
		</v-row>
		<UpdateDomain
			v-if="domainUpdateDialog && domainDetailId"
			:detail-id="domainDetailId"
			:updated-dialog="domainUpdateDialog"
			@close="domainUpdateDialog = false"
			@success="domainUpdated"
		>
		</UpdateDomain>

		<RenewDomain
			v-if="domainRenewDialog && domainDetailId"
			:detail-id="domainDetailId"
			:updated-dialog="domainRenewDialog"
			@close="domainRenewDialog = false"
			@success="domainUpdated"
		>
		</RenewDomain>

		<Dialog :dialog="deleteDialog" :dialog-width="600" @close="deleteDialog = false">
			<template v-slot:title> Delete Domain</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<inline-svg src="media/custom-svg/attention-circle.svg" />
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Deleting Domain is irreversible, Are you sure about deleting it?</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn class="white--text" depressed color="red lighten-1" tile v-on:click="deleteDialog()">
					Yes! Delete
				</v-btn>
				<v-btn depressed tile v-on:click="deleteDialog = false"> No, Close </v-btn>
			</template>
		</Dialog>
	</div>
	<!-- </v-sheet> -->
</template>
<script>
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import MomentJS from "moment-timezone";
import UpdateDomain from "@/view/module/domain/UpdateDomain.vue";
import RenewDomain from "@/view/components/RenewDomain.vue";
import Dialog from "@/view/components/Dialog";
import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import { GET, QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import {
	SET_SELECTED_ROWS,
	SET_TBODY,
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_TOTAL_PAGE,
	SET_TOTAL_ACTIVE,
	SET_TOTAL_EXPIRE,
	SET_TOTAL_EXPIRE_IN_MONTH,
	SET_TOTAL_ROWS,
	SET_COUNT,
} from "@/core/services/store/listing.module";
import objectPath from "object-path";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import PageSummeryBox from "@/view/components/PageSummeryBox";

export default {
	name: "SeoExecutive",
	mixins: [ListingMixin, HeightMixin],
	data() {
		return {
			skipBottomHeight: 56,
			dataTab: "seo_executive",
			pageTitle: "Domain",
			page: 1,
			status: "",
			domainUpdateDialog: false,
			domainDetailId: null,
			domainRenewDialog: false,
			deleteDialog: false,
			checkbox: false,
			projectList: [
				{
					text: "BTC-000011",
					value: "BTC-000011",
				},
				{
					text: "BTC-000041",
					value: "BTC-000041",
				},
			],
			service_providers: [],
			customfilter: {
				billing: null,
				service_provider: null,
				entity: null,
				date_range: [],
				alphabet: null,
			},

			domain: {
				remark:
					"Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..",
			},
			domainList: [],

			entityList: [
				{
					text: "BThurst",
					value: "bthrust",
				},
				{
					text: "Genic Solution",
					value: "genic-solution",
				},
			],
			filter_tabs: [
				{
					text: "ALL DOMAIN",
					value: 10,
					status: "blue",
					count: 0,
					status_count: 0,
				},
				{
					text: "ACTIVE",
					value: 0,
					status: "green",
					count: 0,
					status_count: 0,
				},
				{
					text: "EXPIRED",
					value: 1,
					status: "red",
					count: 0,
					status_count: 0,
				},
				{
					text: "EXPIRE IN 1 MONTH",
					value: 3,
					status: "orange",
					count: 0,
					status_count: 0,
				},
			],

			projectCreateDialog: false,
			notification_on: "Select Notification On...",
			// domainStatusList: [
			// 	{ text: "Expiry", value: 0, color: "red" },
			// 	{ text: "Expiry in 3 Month", value: 1, color: "orange" },
			// { text: "completed", value: 2, color: "green" },
			// { text: "Cancel", value: 3, color: "red" },
			// ],
			projectStatus: 0,
			contact_person: null,
			invoice_number: null,
			notification: null,
			toggle_exclusive: null,
			domain_status_list: [
				{
					id: 192,
					text: "All Domain",
					value: "all",
					type: 40,
					status_color: "blue",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Active",
					value: "active",
					type: 40,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
				{
					id: 193,
					text: "Expired",
					value: "expired",
					type: 40,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Expire in 1 Month",
					value: "expired_in_1_month",
					type: 40,
					status_color: "orange",
					count: 0,
					status_count: 0,
				},
			],
			domainStatusList: [
				{
					text: "Active",
					value: "active",
				},
				{
					text: "Expired",
					value: "expired",
				},
				{
					text: "Renew",
					value: "renew",
				},
			],
			filter: [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
			],
			activeTab: 0,
			selectedRows: [],
			project_status: {
				1: { title: "Draft", color: "cyan" },
				2: { title: "Completed", color: "green" },
				3: { title: "Renewed", color: "orange" },
				4: { title: "Cancelled", color: "red" },
			},
			endpoint: "domain",
			permissions: ["domain:update", "domain:delete"],
			cellWidth: {
				id: 50,
				action: 100,
				entity: 50,
				project_details: 200,
				domain_name: 200,
				billing: 150,
				"cost/sales_price": 150,
				remark: 200,
				status: 100,
				last_update: 100,
			},
		};
	},
	methods: {
		exportCSV() {
			// http://127.0.0.1:8000/api/v1/domain-export
			ApiService.setHeader();
			let downloadURL = process.env.VUE_APP_API_URL + `domain-export`;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);
			const customName = "Domain (" + this.formatDateTimeRawWithoutTime(new Date()) + ").csv";
			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(blobURL);
				});
		},
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		hasKey(object, path) {
			return objectPath.has(object, path);
		},
		routeToDetail(th, id) {
			if (th.checkbox || th.key == "action") {
				return false;
			} else {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: "domain-detail",
					params: { id },
					query: { ...filterQuery, t: new Date().getTime() },
				});
			}
		},
		domainUpdated() {
			this.domainUpdateDialog = false;
			this.domainRenewDialog = false;
			this.getListing();
		},
		changeActiveTab(activeTab) {
			this.activeTab = activeTab;
			this.status = activeTab;
		},
		getStatusColor(status) {
			if (status == "Expired") {
				return "red";
			} else if (status == "Expire In 1 Month") {
				return "orange";
			} else if (status == "Active") {
				return "green";
			}
		},
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
		getDomainListing() {
			this.$store
				.dispatch(GET, { url: `domain` })
				.then((data) => {
					console.log("getDomainListing => ", data);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		selectRow() {
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		statusFilterRows() {
			this.allFiltersStatus();
			this.pageLoading = true;

			const _filter = {
				status: this.status,
				entity: this.customfilter.entity,
				service_provider: this.customfilter.service_provider,
				billing: this.customfilter.billing,
				daterange: this.customfilter.date_range,
				alphabet: this.customfilter.alphabet,
				// daterange: this.customfilter.daterange[1],
				// current_page: 0,
				// per_page: 15
			};

			this.$store
				.dispatch(QUERY, {
					url: `domain`,
					data: { ..._filter },
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_TOTAL_ACTIVE, data.total_active);
					this.$store.commit(SET_TOTAL_EXPIRE, data.total_expire);
					this.$store.commit(SET_TOTAL_EXPIRE_IN_MONTH, data.total_expire_1_month);
					this.$store.commit(SET_TOTAL_ROWS, data.totalRows);
					this.$store.commit(SET_COUNT, data.count);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus() {
			this.$router.push({
				name: "domain",
				query: {
					status: this.status,
					entity: this.customfilter.entity,
					service_provider: this.customfilter.service_provider,
					billing: this.customfilter.billing,
					date_range: this.customfilter.date_range,
					alphabet: this.customfilter.alphabet,
					// daterange: this.customfilter.daterange[1],
					t: new Date().getTime(),
				},
			});
		},
		resetFilters() {
			this.customfilter = {
				billing: null,
				status: 0,
				service_provider: null,
				entity: null,
				date_range: [],
			};
			this.statusFilterRows();
		},
		doAction(key = "", data = {}) {
			switch (key) {
				case "update":
					this.domainUpdateDialog = true;
					this.domainDetailId = data.id;
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "renew":
					this.domainRenewDialog = true;
					this.domainDetailId = data.id;
					break;
				default:
					console.log({ key, data });
					break;
			}
		},
		getServiceProviders() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "project-setting" })
				// .dispatch(GET, { url: "get-all-projects" })
				.then((data) => {
					_this.service_providers = [...data.serverProvider];
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
		taskStatusBorder: function () {
			return function name(status) {
				if (status == "Expired") {
					return "border:1px solid red";
				}
				if (status == "Active") {
					return "border:1px solid green";
				}
			};
		},
		checkAll: {
			get() {
				return this.tbody.length > 0
					? this.selectedRows.length == this.tbody.length
						? true
						: false
					: false;
			},
			set(value) {
				if (value) {
					let _selectedRows = this.tbody.map((v) => v.id);
					this.selectedRows = [..._selectedRows];
					this.$store.commit(SET_SELECTED_ROWS, [..._selectedRows]);
				} else {
					this.selectedRows = [];
					this.$store.commit(SET_SELECTED_ROWS, []);
				}
			},
		},
	},
	// watch: {
	// 	customfilter: {
	// 		deep: true,
	// 		handler: function () {
	// 			this.statusFilterRows();
	// 		},
	// 	},
	// },
	components: {
		SelectInput,
		DateRangePicker,
		ImageTemplate,
		Chip,
		ShowTextAreaValue,
		Dialog,
		RenewDomain,
		UpdateDomain,
		PageSummeryBox,
	},
	mounted() {
		this.getServiceProviders();
	},
	beforeMount() {
		let query = this.$route.query;
		if (Object.keys(query).length > 1) {
			this.customfilter.billing = query.billing ? query.billing : null;
			this.customfilter.service_provider = query.service_provider ? query.service_provider : null;
			this.customfilter.entity = query.entity ? query.entity : null;
			this.customfilter.date_range = query.date_range ? query.date_range : null;
			this.customfilter.alphabet = query.alphabet ? query.alphabet : null;
		}
	},
};
</script>
