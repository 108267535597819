<template>
	<v-flex
		:class="`${getColorClass(color)} lighten-5 summary-details cursor-pointer`"
		:style="`padding: 8px 5px 0px 0px; border: ${isActive ? '4px' : '1px'} solid ${borderColor(
			color
		)} !important; border-radius:5px;`"
		v-on:click="clickEvent($event)"
	>
		<div class="d-flex align-itms-center">
			<div
				:class="`${getTextColorClass(color)} darker name text-capitalize ml-2 summary-text`"
				style="text-align: left !important"
				v-if="text"
			>
				{{ getText(text) }}
			</div>
			<v-spacer />
			<div :class="`number ${getTextColorClass(color)} darker ms-2`">
				{{ count }}
			</div>
		</div>
		<div class="d-flex align-items-center">
			<div>
				<v-icon style="margin-left: -10px" :color="color">mdi-text-box-check-outline</v-icon>
			</div>
		</div>
		<div
			:class="`amount ${getTextColorClass(color)} darker ms-2 text-end text-h4 fw-600`"
			v-if="showamount"
		>
			{{ formatMoney(amount) }}
		</div>
	</v-flex>
</template>
<script>
export default {
	name: "page-summery",
	title: "Page Summery",
	props: {
		color: {
			type: String,
			default: null,
		},
		count: {
			type: Number,
			default: 0,
		},
		amount: {
			type: Number,
			default: 0,
		},
		text: {
			type: String,
			default: null,
		},
		showamount: {
			type: Boolean,
			default: false,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		clickEvent(event) {
			this.$emit("click", event);
		},
		borderColor(color) {
			let colorCode = "";
			switch (color) {
				case "blue":
					colorCode = "#2196F3";
					break;
				case "purple":
					colorCode = "#9c27b0";
					break;
				case "red":
					colorCode = "#F44336";
					break;
				case "green":
					colorCode = "#4CAF50";
					break;
				case "orange":
					colorCode = "#ff9800";
					break;
				case "pink":
					colorCode = "#e91e63";
					break;
				case "cyan":
					colorCode = "#00bcd4";
					break;
				case "grey":
					colorCode = "#9e9e9e";
					break;
				case "#008080":
					colorCode = "#008080";
					break;
				case "#191970":
					colorCode = "#191970";
					break;
			}
			return colorCode;
		},
		getColorClass(color) {
			if (color == "#008080") {
				return "bg-light-d-green";
			} else if (color == "#191970") {
				return "bg-light-d-blue";
			} else {
				return color;
			}
		},
		getTextColorClass(color) {
			if (color == "#008080") {
				return "d-green--text";
			} else if (color == "#191970") {
				return "d-blue--text";
			} else {
				return `${color}--text`;
			}
		},
		getText(text) {
			if (text == "Overdue For Issuance") {
				return "Overdue Issuance";
			} else if (text == "Pending For Issuance") {
				return "Pending Issuance";
			} else {
				return text;
			}
		},
	},
	// computed: {
	// 	isActive() {
	// 		return this.$attrs.class?.includes("active");
	// 	},
	// },
};
</script>
